<template>
  <div id="divPriceListWrapper" class="price-list-container">
    <BrandProductPriceToolbar partner="PMI" :routes="routes" :active-route="routes.priceList" />
    <hr />
    <div class="title-bar">
      <BrandLogo :width="70" :height="70" :image="partner.image" />
      <div class="title-bar-content">
        <a @click="download" class="download-icon">
          <VueIcon
            :iconName="icon.share.name"
            :width="icon.share.width"
            :height="icon.share.height"
          ></VueIcon>
        </a>
      </div>
    </div>
    <hr />
    <div class="pl-img-wrapper">
      <img :src="imageUrl" alt="price-list" />
    </div>
  </div>
</template>
<script>
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import FileSaver from 'file-saver';
import BrandProductPriceToolbar from '@/components/brand/BrandProductPriceToolbar/BrandProductPriceToolbar.vue';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import productApi from '@/services/Api/product';
// import DownloadFile from '@/utils/downloadFile.js';
// var FileSaver = require('file-saver');

export default {
  name: 'PriceList',
  data() {
    return {
      partner: {},
      pageInfo: '',
      imageUrl: '',
    };
  },
  computed: {
    icon() {
      return {
        share: ICON_VARIABLES.share,
      };
    },
    routes() {
      return {
        priceList: `${RoutesPmi.PriceList.path}`,
        productList: `${RoutesPmi.ProductList.path}`,
      };
    },
  },
  beforeMount() {
    productApi.getPriceList().then(res => {
      if (res && res.data) {
        const { Data } = res.data;

        this.pageInfo = Data.pageInfo;
        this.imageUrl = Data.imageUrl;
        this.partner = Data.partner;
      }
    });
  },
  components: {
    BrandProductPriceToolbar,
    VueIcon,
    BrandLogo,
  },
  methods: {
    download() {
      let fileExtension = this.imageUrl.split('.').pop();
      FileSaver.saveAs(this.imageUrl, `fiyat-listesi.${fileExtension}`);
      // let dlApi = new DownloadFile();
      // dlApi.getBase64FromImageUrl('bokImg');
      // new DownloadFile().download('fiyat-listesi', this.imageUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flex-center {
  display: flex;
  align-items: center;
}
.price-list-container {
  .price-list-toolbar {
    padding: palette-space-level(20);
  }
  .title-bar {
    @include flex-center;
    padding: palette-space-level(20);
    margin-bottom: palette-space-level(5);
    .title-bar-content {
      @include flex-center;
      position: relative;
      width: 100%;
      justify-content: space-between;
      padding-left: palette-space-level(10);
      padding-right: palette-space-level(10);
      .download-icon {
        position: absolute;
        right: 0;
      }
    }
  }
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.5;
    border-top: solid 1px #b4c2d3;
  }
  .pl-img-wrapper {
    text-align: center;
    margin-bottom: palette-space-level(30);
  }
}
</style>
